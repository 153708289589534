.HomeServicesContainer {
  padding: 8vw 0 0;
}

.HomeServicesContainer__SectionTitle.SectionTitle {
  margin-left: 5%;
}

.HomeServicesContainer-Subcontainer {
  transition: 1s;
  will-change: opacity;
}

.ServiceContainer {
  width: 90%;
  margin: 0 auto 3vw;
  overflow: hidden;
  transition: 0.3s;
  will-change: height;
  border-bottom: 1px solid #dddddd;
}

.ServiceContainer-Title {
  width: 100%;
  margin: 0 0 5vw;
  padding: 4vw 0;
  font-size: 9vw;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  font-family: $Font1;
}

.ServiceContainer-Title-Arrow {
  width: 3.5vw;
  margin-right: 5vw;
  transition: 0.3s;
  will-change: transform;
}

.ServiceContainer-ServicesWrapper {
  width: 100%;
  margin: 0 auto;
}

.ServiceContainer-ServicesWrapper--Open {
  opacity: 1;
}

.ServiceContainer-ServicesWrapper--Close {
  opacity: 0;
}

.ServicesSlide-Subtitle {
  width: 100%;
  margin: 0 0 5vw;
  font-size: 8vw;
  font-weight: 500;
  font-family: $Font1;
}

.ServicesSlide-Text {
  width: 100%;
  height: 12vw;
  margin: 0 auto;
  font-weight: 300;
  font-size: 3.7vw;
  display: flex;
  align-items: center;
  font-family: $Font2;
  &__Number {
    width: 9.5vw;
    margin-right: 2vw;
    font-weight: 700;
    font-size: 5vw;
    font-family: $Font1;
  }
}

.HomeServicesContainer-ImageContainer {
  width: 100%;
  height: 85vw;
  margin: 15vw 0;
  background-image: url("../../Assets/Images/services-bgimg-mobile.webp");
  background-size: 70%;
  background-repeat: no-repeat;
  transition: 1s;
  will-change: background-position opacity;
}

.ServicesBgPosition1 {
  background-position: 80% top;
}

.ServicesBgPosition2 {
  background-position: 80% top;
}

.ServicesBgPosition3 {
  background-position: 80% top;
}

.ServicesBgPosition4 {
  background-position: 80% top;
}

.HomeServicesContainer-ImageContainer-Image {
  width: 100%;
  height: 100%;
  background-size: 75%;
  background-repeat: no-repeat;
  transition: 1s;
  will-change: background-image;
}

.ServicesImage-1 {
  background-image: url("../../Assets/Images/servicios1.webp");
  background-position: 20% bottom;
}
.ServicesImage-2 {
  background-image: url("../../Assets/Images/servicios2.webp");

  background-position: 20% bottom;
}
.ServicesImage-3 {
  background-image: url("../../Assets/Images/servicios3.webp");

  background-position: 20% bottom;
}
.ServicesImage-4 {
  background-image: url("../../Assets/Images/servicios4.webp");

  background-position: 20% bottom;
}

@media screen and (min-width: 1099px) {
  .HomeServicesContainer {
    width: 80%;
    height: 42vw;
    margin: 0 auto;
    padding: 5vw 0 10vw;

    position: relative;
  }

  .HomeServicesContainer__SectionTitle.SectionTitle {
    margin-left: 2.5%;
  }

  .ServiceContainer {
    width: 45%;
    margin: 0 0 1.5vw 55%;
    border-bottom: 1px solid #dddddd;
  }

  .ServiceContainer-Title {
    margin: 0 0 1vw;
    padding: 0;
    font-size: 3vw;
    cursor: pointer;
    will-change: color;
    transition: 0.3s;
  }

  .ServiceContainer-Title:hover {
    color: $color-variacion2;
  }

  .ServiceContainer-Title-Arrow {
    width: 1vw;
    margin-right: 2vw;
    transition: 0.3s;
    will-change: transform;
  }

  .ServicesSlide-Subtitle {
    width: 57%;
    margin: 0 0 1vw;
    font-size: 2vw;
  }

  .ServicesSlide-Text {
    height: 2.5vw;
    margin: 0 auto;
    font-size: 1vw;

    &__Number {
      width: 3vw;
      margin-right: 0;
      font-size: 1.3vw;
    }
  }

  .HomeServicesContainer-ImageContainer {
    width: 45%;
    height: 28vw;
    top: 10vw;
    margin: 0;
    transition: 0.7s;
    background-size: 70%;
    position: absolute;
  }

  .HomeServicesContainer-ImageContainer-Image {
    width: 100%;
    height: 100%;
    background-size: 75%;
    background-repeat: no-repeat;
    transition: 0.7s;
    will-change: background-image;
  }

  .ServicesBgPosition1 {
    background-position: 80% top;
  }

  .ServicesBgPosition2 {
    background-position: 20% bottom;
  }

  .ServicesBgPosition3 {
    background-position: 90% center;
  }

  .ServicesBgPosition4 {
    background-position: 10% bottom;
  }

  .ServicesImage-1 {
    background-image: url("../../Assets/Images/servicios5.webp");
    background-position: 20% 100%;
  }
  .ServicesImage-2 {
    background-image: url("../../Assets/Images/servicios6.webp");
    background-position: 80% top;
  }
  .ServicesImage-3 {
    background-image: url("../../Assets/Images/servicios7.webp");
    background-position: 50% 50%;
  }
  .ServicesImage-4 {
    background-image: url("../../Assets/Images/servicios8.webp");
    background-position: 80% 60%;
  }
}
