.HomeMetodology {
  transition: 1s;
  will-change: opacity;
  background-color: $color-bgoscuro;
}

.HomeMetodologyContainer {
  width: 85%;
  margin: 0 auto;
  padding: 10vw 0 15vw;
  &__SectionTitle {
    color: $color-claro;
  }
  &__Subcontainer {
    margin: 0 0 12vw;
    transition: 1s;
    will-change: opacity transform;
    &:nth-child(5) {
      margin: 0;
    }
    &--Number {
      margin: 0;
      font-size: 20vw;
      font-weight: 500;
      line-height: 100%;
      transition: 1s;
      will-change: color;
      font-family: $Font1;
    }
    &--Title {
      margin: 0;
      color: $color-claro;
    }
    &--Text {
      margin: 6vw 0 0;
      width: 95%;
      color: $color-terciario;
      & span {
        font-weight: 500;
        color: $color-claro;
      }
    }
    &--Line {
      margin: 10vw 0 4vw;
      transition: 1s;
      will-change: width;
      border-top: 1px solid $color-secundario;
    }
  }
}

@media screen and (min-width: 1099px) {
  .HomeMetodologyContainer {
    width: 80%;
    padding: 5vw 0 10vw;
    display: flex;
    flex-wrap: wrap;
    &__SectionTitle {
      width: 100%;
    }
    &__Subcontainer {
      width: 28%;
      margin: 0 15% 4vw 0;
      &:nth-child(4) {
        margin: 0 15% 0 28%;
      }
      &--Number {
        margin: 0;
        font-size: 6.5vw;
        transition: 0.3s;
        cursor: default;
        will-change: color;
        color: $color-secundario;
      }

      &--Text {
        margin: 2vw 0 0;
      }
      &--Line {
        margin: 2.5vw 0 1vw;
      }
    }
  }

  .HomeMetodologyContainer__Subcontainer--Title.Title {
    font-size: 2.2vw;
  }

  .HomeMetodologyContainer__Subcontainer--Text.Text {
    font-size: 1vw;
  }

  .HomeMetodologyContainer__Subcontainer:hover
    .HomeMetodologyContainer__Subcontainer--Number {
    color: $color-variacion1;
  }
}
