.ContactContainer {
  width: 85%;
  margin: 0 auto;
  padding: 10vw 0;
  &__Title {
    width: 80%;
  }
  &__Subtitle {
    display: none;
  }
  &__Form {
    margin: 10vw 0 0;
    &--Input {
      width: 100%;
      margin-bottom: 8vw;
      padding: 1vw 0;
      border-radius: 0;
      font-size: 4vw;
      border: none;
      outline: none;
      border-bottom: 1px solid #000;
      font-family: $Font3;
      &::placeholder {
        font-family: $Font3;
        color: $color-terciario;
      }
    }
    &--Textarea {
      width: 98.5%;
      height: 25vw;
      border-radius: 0;
      font-size: 4vw;

      border: none;
      outline: none;
      resize: none;
      border-bottom: 1px solid #000;

      color: $color-secundario;
      font-family: $Font3;
      &::placeholder {
        color: $color-terciario;
        font-family: $Font3;
      }
    }
    &--Submit {
      width: 53vw;
      margin: 10vw 0 4vw;
      border: none;
      background-color: transparent;
      & p {
        font-weight: 600;
        color: $color-primario;
      }
    }
    &--Response {
      width: 100%;
      height: 10vw;
      display: flex;
      align-items: center;
      margin-bottom: 3vw;
    }
  }
}

.ContactForm-Loader {
  width: 10vw;
}

.ContactContainer__Title.Title {
  font-weight: 600;
}

.ContactContainer__Form--Submit div.Button-Arrow {
  background-color: rgba(140, 136, 136, 0.2);
}

@media screen and (min-width: 1099px) {
  .ContactContainer {
    width: 80%;
    padding: 6vw 0;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    &__SectionTitle {
      width: 100%;
    }
    &__Title {
      width: 45%;
      margin-left: 8%;
    }
    &__Subtitle {
      width: 18vw;
      bottom: 16vw;
      margin-left: 8%;
      display: flex;
      position: absolute;
      font-family: $Font2;
    }
    &__Form {
      width: 35%;
      margin: 1.5vw 0 0 5%;
      &--Input {
        margin-bottom: 2vw;
        padding: 0.5vw 0;
        font-size: 1.1vw;

        &::placeholder {
          opacity: 0.7;
          font-size: 1.1vw;
        }
      }
      &--Textarea {
        width: 100%;
        height: 8vw;
        font-size: 1.1vw;
        &::placeholder {
          opacity: 0.7;
          font-size: 1.1vw;
        }
      }
      &--Submit {
        width: 13vw;
        margin: 2vw 0 0;
      }
      &--Response {
        width: 100%;
        height: 2vw;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
      }
    }
  }

  .ContactForm-Loader {
    width: 2vw;
  }

  .ContactContainer__Title.Title {
    font-size: 3.9vw;
    margin-top: 1vw;
  }

  .Submit-Arrow.Button-Arrow {
    width: 5vw;
    height: 5vw;
    background-size: 1.5vw;
    background-position: 1.5vw center;
  }
  .ContactContainer__Form--Submit.Button {
    padding: 2vw 0;
  }
  .Submit-Text.Button-Text {
    font-size: 1.5vw;
  }
}
