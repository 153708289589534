.HomeProjects {
  background-color: $color-bgoscuro;
  transition: 0.3s;
  will-change: background-color;
}

.HomeProjectsContainer {
  width: 85%;
  margin: 0 auto;
  padding: 50vw 0 8vw;
  &__SectionTitle {
    color: $color-claro;
  }
  &__Title {
    width: 95%;
    margin: 0;
    color: $color-claro;
  }
  &__Line {
    margin: 12vw 0 0vw -7.5vw;
    display: flex;
    transition: 1s;
    will-change: opacity width;
    border-top: 2px solid $color-secundario;
  }
  &__Description {
    width: 82%;
    margin: 8vw 0 25vw 18%;
    color: $color-claro;
    & span {
      font-weight: 500;
    }
  }
  &__Projects {
    &--Title {
      display: flex;
      width: 100%;
      margin: 0 0 15vw 0;
      color: $color-claro;
    }
  }
  &__MoreButton {
    text-decoration: none;
  }
}

.ProjectBox {
  margin: 0 0 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  will-change: opacity;
  transition: 1s;
  &__ImageContainer {
    width: 100%;

    &--Image {
      width: 100%;
    }
  }
  &__Title {
    margin: 5vw 0 2vw;
    font-size: 6.5vw;
    font-weight: 600;
    color: $color-claro;
  }
  &__Location {
    margin: 0 0 5vw;
    font-size: 5vw;
    font-weight: 400;
    color: $color-terciario;
  }
}

@media screen and (min-width: 1099px) {
  .HomeProjectsContainer {
    width: 75%;
    padding: 10vw 0 6vw;
    position: relative;
    &__Circle {
      width: 5vw;
      height: 5vw;
      top: 18.2vw;
      left: 35vw;
      z-index: 0;
      border-radius: 100vw;
      position: absolute;
      background-color: $color-primario;
    }
    &__Title {
      width: 70%;
      position: relative;
      z-index: 1;
    }
    &__Line {
      width: 0vw;
      margin: 9vw 0 15vw;
      border-top: 2px solid $color-secundario;
    }
    &__Description {
      width: 41%;
      right: 0;
      margin: -18.5vw 0 0;
      position: absolute;
    }
    &__Projects {
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &--Title {
        margin-bottom: 5vw;
      }
    }
  }

  .ProjectBox {
    width: 45%;
    height: 44vw;
    margin: 0 0 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:nth-child(3) {
      margin-top: 7vw;
    }
    &:nth-child(4) {
      margin-top: 0;
    }
    &:nth-child(5) {
      margin-top: 7vw;
    }
    &:nth-child(6) {
      margin-top: 0;
    }
    &:nth-child(7) {
      margin-top: 7vw;
    }
    &:nth-child(8) {
      margin-top: 0vw;
    }
    &:nth-child(9) {
      margin-top: 7vw;
    }
    &__ImageContainer {
      width: 100%;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      &--Image {
        width: 100%;
        transition: 0.5s;
        will-change: transform;
      }
    }
    &__Title {
      margin: 2vw 0 0.2vw;
      font-size: 1.7vw;
    }
    &__Location {
      margin: 0 0;
      font-size: 1.2vw;
    }
  }

  .HomeProjectsContainer__Description.Desktop {
    display: block;
  }
  .HomeProjectsContainer__Description.Mobile {
    display: none;
  }

  .ProjectBox__ImageContainer--Image:hover {
    transform: scale(1.1);
  }
}
