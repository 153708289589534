.HomeHero {
  padding: 30vw 0 56vw;
}

.HomeHeroContainer {
  position: relative;
  background-color: $color-claro;
  &__Media {
    display: none;
  }
  &__Title {
    width: 80%;
    height: 10vw;
    margin: 0 0 0 5%;
  }
  &__Subtitle {
    display: none;
  }
  &__ImageContainer {
    width: 100%;
    height: 75vw;
    bottom: -84vw;
    position: absolute;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-image: url(../../Assets/Images/home-hero-bg-mobile.webp);
    background-size: 81%;
    background-size: 60vw;
    background-repeat: no-repeat;
    background-position: right bottom;
    overflow: hidden;
    &--Image {
      width: 85%;
      margin-top: 5vw;
      border-top-left-radius: 17vw;
      overflow: hidden;
      & img {
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: 1099px) {
  .HomeHero {
    padding: 5vw 0vw 0;
  }
  .HomeHeroContainer {
    padding: 8vw 0 0;
    position: relative;
    &__Media {
      top: 9vw;
      left: 5%;
      position: absolute;
      display: flex;
      flex-direction: column;
      &--Link {
        margin: 0.7vw 0;
        & img {
          opacity: 0;
        }
      }
    }
    &__Title {
      width: 46%;
      height: 4vw;

      margin: 0vw 0 0.2vw 12%;
    }
    &__Subtitle {
      width: 20%;
      margin: 5vw 0 6.5vw 12%;
      display: flex;
      flex-direction: column;
      & span {
        font-weight: 600;
      }
    }
    &__ImageContainer {
      width: 40vw;
      height: 27vw;
      right: 4%;
      bottom: -11.5vw;
      transition: 0.5s;
      background-size: 10vw;
      background-image: url(../../Assets/Images/home-hero-bg-desktop.webp);
      &--Image {
        width: 34vw;
        height: 25vw;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 8vw;
        transition: 0.5s;

        & img {
          transition: 0.5s;
        }
      }
    }
  }

  .HomeHeroContainer__ImageContainer--Image:hover .Hero-Image {
    width: 36vw;
  }

  .Media-Container-Animation:hover .Hero-Media-Animation {
    background-color: $color-primario;
  }

  .Media-Container-Animation:hover .Hero-Media-Image {
    filter: brightness(0) saturate(100%) invert(96%) sepia(0%) saturate(7476%)
      hue-rotate(77deg) brightness(105%) contrast(106%);
  }
}
