.Footer {
  padding: 20vw 0 10vw;
  border-top: 2px solid #ddd;
}

.FooterContainer {
  width: 85%;
  margin: 0 auto;
  position: relative;

  &__LogoSubcontainer {
    width: 52%;
    margin: 0 0 10vw;
    &--Image {
      width: 88%;
    }
    &--Text {
      margin: 4vw 0 0;
      display: flex;
      flex-direction: column;
      font-family: $Font2;
      & span {
        font-weight: 600;
      }
    }
  }
  &__ContactSubcontainer {
    width: 62%;
    margin: 0 0 10vw;
    &--Title {
      margin: 0 0 2vw;
    }
    &--Text {
      margin: 0;

      font-family: $Font3;
      & span {
        font-weight: 600;
      }
    }
    &--Location {
      margin: 3vw 0 0;

      font-family: $Font3;
    }
  }
  &__SiteMapSubcontainer {
    margin: 0 0 10vw;
    &--Title {
      margin: 0 0 4vw;
    }
    &--Link {
      margin: 0 0 1vw 0;
      display: flex;
      text-decoration: none;
      align-items: center;
      & p {
        margin: 0;

        font-family: $Font3;
        color: $color-primario;
      }
      & img {
        width: 3vw;
        margin: 1vw 0 0 3.5vw;
      }
    }
  }
  &__MediaSubcontainer {
    display: flex;
    flex-wrap: wrap;
    &--Title {
      width: 100%;
      margin: 0 0 6vw;
    }
    &--Link {
      margin: -2vw 2vw 0 0;
    }
  }
  &__ArrowUp {
    width: 7vw;
    height: 7vw;
    bottom: 0vw;
    right: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    &--Image {
      width: 100%;
    }
  }
}

.HomeMetodologyContainer__Subcontainer--Text.Desktop {
  display: none;
}

.Footer-Title.Title {
  font-weight: 600;
}

@media screen and (min-width: 1099px) {
  .Footer {
    width: 85%;
    margin: 0 auto;
    padding: 5vw 0;
    border-top: 2px solid #ddd;
  }
  .FooterContainer {
    width: 95%;
    display: flex;
    &__LogoSubcontainer {
      width: 45%;
      margin: 0;
      &--Image {
        width: 30%;
        margin-bottom: 1vw;
      }
      &--Text {
        width: 35%;
        margin: 0;
      }
    }
    &__ContactSubcontainer {
      width: 15.5%;
      margin: 0 9.75% 0 0;
      &--Title {
        margin: 0 0 0.5vw;
      }

      &--Location {
        margin: 1vw 0 0;
      }
    }
    &__SiteMapSubcontainer {
      width: 11%;
      margin: 0 9.75% 0 0;
      &--Title {
        margin: 0 0 0.5vw;
      }
      &--Link {
        margin: 0;
        & p {
          margin: 0 0 0.1vw;
        }
        & img {
          width: 0.7vw;
          margin: 0 0 0 1vw;
          transition: 0.5s;
          will-change: margin filter;
        }
      }
    }
    &__MediaSubcontainer {
      width: 9%;
      height: 5vw;
      justify-content: space-between;
      align-items: flex-start;
      &--Title {
        margin: 0;
        text-align: center;
      }
      &--Link {
        margin: -0.5vw 0 0;
      }
    }
    &__ArrowUp {
      display: none;
    }
  }

  .FooterContainer__SiteMapSubcontainer--Link:hover .SiteMap-Arrow {
    margin: 0 0 0 1.5vw;
    filter: invert(33%) sepia(65%) saturate(4994%) hue-rotate(246deg)
      brightness(94%) contrast(94%);
  }

  .Footer-Ig {
    width: 1.2vw;
    height: 1.2vw;
  }
  .Footer-Be {
    width: 1.5vw;
    height: 1.5vw;
  }
  .Footer-In {
    width: 1.2vw;
    height: 1.2vw;
  }

  .Footer-Media-Image {
    will-change: filter;
    transition: 0.3s;
  }

  .Media-Container-Animation:hover .Footer-Media-Animation {
    background-color: $color-primario;
  }

  .Media-Container-Animation:hover .Footer-Media-Image {
    filter: brightness(0) saturate(100%) invert(96%) sepia(0%) saturate(7476%)
      hue-rotate(77deg) brightness(105%) contrast(106%);
  }

  .HomeMetodologyContainer__Subcontainer--Text.Mobile {
    display: none;
  }
  .HomeMetodologyContainer__Subcontainer--Text.Desktop {
    display: flex;
  }
}
