// Skeleton
.SkeletonContainer {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Skeleton {
  width: 100%;
  height: 100%;
  will-change: transform;
  background: rgb(240, 240, 240);
  background: linear-gradient(
    45deg,
    rgba(240, 240, 240, 1) 33%,
    rgba(252, 252, 252, 1) 100%
  );
  animation-name: SkeletonAnimation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

// NavBar Animation
.Header-Animation {
  will-change: transform;
  animation-name: headerAnimation;
  animation-fill-mode: forwards;
  transform: translateY(-20vw);
}

.NavBarContainer__MenuButton.Header-Animation {
  animation-delay: 0.9s;
  animation-duration: 0.5s;
}
.NavBarContainer__Logo.Header-Animation {
  animation-delay: 0s;
  animation-duration: 1s;
}
.NavBarContainer__LanguageButton.Header-Animation {
  animation-duration: 0.5s;
  animation-delay: 1.2s;
}

// Menu is active
#MenuContainer {
  z-index: 100;
}
// Menu inicialization
#MenuContainerZIndex.Menu.Menu-Animation {
  z-index: -100;
  opacity: 0;
  margin-left: -100%;
}

// Menu closes
.Menu-Animation {
  animation: menuClose;
  animation-duration: 0.5s;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;
  z-index: 100;
  pointer-events: all;
}

// Menu is active
.MenuIsActive {
  animation: menuOpen;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  z-index: -10;
  pointer-events: none;
}

// --Navbar links Animations
// ----OUT
.Navbar-Links-AnimationOut {
  animation: navbarTranslateXAnimationOut;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}
.Navbar-Links-AnimationOut:nth-child(1) {
  animation-delay: 0.6s;
}
.Navbar-Links-AnimationOut:nth-child(2) {
  animation-delay: 0.5s;
}
.Navbar-Links-AnimationOut:nth-child(3) {
  animation-delay: 0.4s;
}
.Navbar-Links-AnimationOut:nth-child(4) {
  animation-delay: 0.3s;
}

// ----IN
.Navbar-Links-AnimationIn {
  transform: translateX(-45vw);

  animation: navbarTranslateXAnimationIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.Navbar-Links-AnimationIn:nth-child(1) {
  animation-delay: 1.3s;
}
.Navbar-Links-AnimationIn:nth-child(2) {
  animation-delay: 1.4s;
}
.Navbar-Links-AnimationIn:nth-child(3) {
  animation-delay: 1.5s;
}
.Navbar-Links-AnimationIn:nth-child(4) {
  animation-delay: 1.6s;
}

// --Navbar Button Icon
// ----OUT
.Navbar-ButtonIcon-AnimationOut {
  animation: ScaleAnimationOut;
  animation-duration: 0.5s;
  animation-delay: 0;
  animation-fill-mode: forwards;
}
// ----IN
.Navbar-ButtonIcon-AnimationIn {
  animation: ScaleAnimationIn;
  animation-duration: 0.5s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

// --Navbar Button Text
// -----OUT
.Navbar-ButtonText-AnimationOut {
  animation: navbarTranslateYAnimationOut;
  animation-delay: 0.5s;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}
// -----IN
.Navbar-ButtonText-AnimationIn {
  transform: translateY(30vw);
  animation: navbarTranslateYAnimationIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 0.8s;
}

// --Navbar Menu Logo
// -----OUT
.Navbar-Logo-AnimationOut {
  animation: navbarTranslateYAnimationOut;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}
// -----IN
.Navbar-Logo-AnimationIn {
  transform: translateY(30vw);
  animation: navbarTranslateYAnimationIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}

// --Navbar Language
// -----OUT
.Navbar-Language-AnimationOut {
  animation: navbarTranslateYAnimationOut;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.1s;
}
// -----IN
.Navbar-Language-AnimationIn {
  transform: translateY(30vw);
  animation: navbarTranslateYAnimationIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}

// --Line
// -----OUT
.Navbar-MediaLine-AnimationOut {
  transform: translateX(0);
  animation-name: navbarLineTranslateXOut;
  animation-duration: 1s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
}
// -----IN
.Navbar-MediaLine-AnimationIn {
  transform: translateX(-100vw);

  animation-name: navbarLineTranslateXIn;
  animation-duration: 1s;
  animation-delay: 0.7s;
  animation-fill-mode: forwards;
}

// --NavBar MediaImage Animations
// --OUT
.Navbar-MediaLinks-AnimationOut {
  animation: navbarTranslateYAnimationOut;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}
// ----IN
.Navbar-MediaLinks-AnimationIn {
  transform: translateY(30vw);
  animation: navbarTranslateYAnimationIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.Menu-Ig {
  animation-delay: 0.4s;
}
.Menu-Be {
  animation-delay: 0.5s;
}
.Menu-In {
  animation-delay: 0.6s;
}
.Menu-Wsp {
  animation-delay: 0.7s;
}

//Hero Animations
// Hero Title
.Span-Animation {
  overflow: hidden;
  position: relative;
}

.Span-Animation .Span-Animation-Block {
  animation-name: textTranslateAnimation;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  will-change: transform;
  transform: translateY(10vw);
  background-color: $color-claro;
}

// Hero Image Container
.Image-Container-Animation {
  will-change: background-size width transform;
  animation-name: heroImageContainerAnimation;
  animation-duration: 1.1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}
// Hero Image Background
.Image-Animation {
  will-change: transform;
  animation-name: heroImageAnimation;
  animation-duration: 1.1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  transform: translateX(7.5vw) translateY(10vw);
}

// Hero Image
.Image-Img-Animation {
  will-change: transform;
  animation-name: heroImageImgAnimation;
  animation-duration: 1.5s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  transform: scale(1.1);
}

//Hero text
// Line 1
.HeroTitle1 span {
  animation-delay: 1s;
}
// Line 2
.HeroTitle2 span {
  animation-delay: 1.2s;
}
// Line 3
.HeroTitle3 span {
  animation-delay: 1.4s;
}
// Line 4
.HeroTitle4 span {
  animation-delay: 1.6s;
}

// SERVICES SECTION
.ServiceContainer--Open {
  animation-name: serviceContainerOpen;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
  height: 20vw;
}

.ServiceContainer--Open2 {
  animation-name: serviceContainerOpen2;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
  height: 20vw;
}

.ServiceContainer--Close {
  animation-name: serviceContainerClose;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0;
  height: 100vw;
}

.ServiceContainer--Close2 {
  animation-name: serviceContainerClose2;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0;
  height: 90vw;
}

.ServiceContainer-Title-Arrow--Open {
  animation-name: rotateZanimationIn;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
  transform: rotateZ(0deg);
}

.ServiceContainer-Title-Arrow--Close {
  animation-name: rotateZanimationOut;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0;
  transform: rotateZ(180deg);
}

// Project List
.Projects-SectionTitle-Animation,
.ProjectsList-Categories-Animation,
.ProjectList-Contaier-Animation {
  animation-name: opacityAnimation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.Projects-SectionTitle-Animation {
  animation-delay: 0.7s;
}

.ProjectsList--Title1 span {
  animation-delay: 0.7s;
}
.ProjectsList--Title2 span {
  animation-delay: 1s;
}
.ProjectsList--Title3 span {
  animation-delay: 1.3s;
}

.ProjectsList-Categories-Animation {
  animation-delay: 1.7s;
}

.ProjectList-Contaier-Animation {
  animation-delay: 2s;
}

.ProjectDetail-Image-Animation,
.ProjectDetail-Title-Animation {
  animation-name: opacityAnimation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.ProjectDetail-Image-Animation {
  animation-delay: 0.7s;
}
.ProjectDetail-Title-Animation {
  animation-delay: 1s;
}
.ProjectDetail-Description-Animation {
  animation-name: ProjectDetailDescription;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 1.3s;
}
@media screen and (min-width: 1099px) {
  // NavBar
  // NavBar-Links
  // OUT
  .Navbar-Links-AnimationOut {
    animation: navbarTranslateYAnimationOut;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
  }
  .Navbar-Links-AnimationOut:nth-child(1) {
    animation-delay: 0.1s;
  }
  .Navbar-Links-AnimationOut:nth-child(2) {
    animation-delay: 0.2s;
  }
  .Navbar-Links-AnimationOut:nth-child(3) {
    animation-delay: 0.3s;
  }
  .Navbar-Links-AnimationOut:nth-child(4) {
    animation-delay: 0.4s;
  }

  // IN
  .Navbar-Links-AnimationIn {
    transform: translateY(5vw);
    animation: navbarTranslateYAnimationIn;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
  }
  .Navbar-Links-AnimationIn:nth-child(1) {
    animation-delay: 0.8s;
  }
  .Navbar-Links-AnimationIn:nth-child(2) {
    animation-delay: 0.9s;
  }
  .Navbar-Links-AnimationIn:nth-child(3) {
    animation-delay: 1s;
  }
  .Navbar-Links-AnimationIn:nth-child(4) {
    animation-delay: 1.1s;
  }

  // Navbar Logo

  .Navbar-Logo-AnimationOut {
    animation: navbarTranslateYAnimationOut;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-delay: 0.8s;
  }

  .Navbar-Logo-AnimationIn {
    transform: translateY(0vw);

    animation: navbarTranslateYAnimationIn;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    animation-delay: 0.6s;
  }

  // Navbar Media

  // OUT
  .Navbar-MediaLinks-AnimationOut {
    animation: navbarTranslateYAnimationOut;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }
  .Menu-Ig.Navbar-MediaLinks-AnimationOut {
    animation-delay: 0.3s;
  }
  .Menu-Be.Navbar-MediaLinks-AnimationOut {
    animation-delay: 0.4s;
  }
  .Menu-In.Navbar-MediaLinks-AnimationOut {
    animation-delay: 0.5s;
  }
  .Menu-Wsp.Navbar-MediaLinks-AnimationOut {
    animation-delay: 0.6s;
  }

  // IN
  .Navbar-MediaLinks-AnimationIn {
    transform: translateY(5vw);
    animation: navbarTranslateYAnimationIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }
  .Menu-Ig.Navbar-MediaLinks-AnimationIn {
    animation-delay: 1.6s;
  }
  .Menu-Be.Navbar-MediaLinks-AnimationIn {
    animation-delay: 1.7s;
  }
  .Menu-In.Navbar-MediaLinks-AnimationIn {
    animation-delay: 1.8s;
  }
  .Menu-Wsp.Navbar-MediaLinks-AnimationIn {
    animation-delay: 1.9s;
  }

  // Navbar Button

  // OUT
  .Navbar-ButtonIcon-AnimationOut {
    animation: ScaleAnimationOut;
    animation-duration: 0.5s;
    animation-delay: 0;
    animation-fill-mode: forwards;
  }
  // IN
  .Navbar-ButtonIcon-AnimationIn {
    animation: ScaleAnimationIn;
    animation-duration: 0.5s;
    animation-delay: 3s;
    animation-fill-mode: forwards;
  }

  // OUT
  .Navbar-ButtonText-AnimationOut {
    animation: navbarTranslateYAnimationOut;
    animation-duration: 0.5s;
    animation-delay: 0.4s;
    animation-fill-mode: forwards;
  }

  //  IN
  .Navbar-ButtonText-AnimationIn {
    animation: navbarTranslateYAnimationIn;
    animation-duration: 1s;
    animation-delay: 2.3s;
    animation-fill-mode: forwards;
  }

  // Hero
  // Hero Title
  .Span-Animation {
    overflow: hidden;
    position: relative;
  }

  .Span-Animation .Span-Animation-Block {
    animation-name: textTranslateAnimation;
  }

  // Hero Media
  .Hero-MediaImg-Animation {
    animation-name: opacityAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }
  // Instagram
  .Hero-Media-Image.Hero-Ig {
    animation-delay: 2.5s;
  }
  // Behance
  .Hero-Media-Image.Hero-Be {
    animation-delay: 2.8s;
  }
  // Linkedin
  .Hero-Media-Image.Hero-In {
    animation-delay: 3.1s;
  }

  // Line 1
  .HeroTitle1 span {
    animation-delay: 0.5s;
  }
  // Line 2
  .HeroTitle2 span {
    animation-delay: 0.8s;
  }
  // Line 3
  .HeroTitle3 span {
    animation-delay: 1.1s;
  }

  // Hero Subtitle
  .Hero-Subtitle-Animation {
    opacity: 0;
    animation-name: opacityAnimation;
    animation-duration: 2s;
    animation-delay: 1.7s;
    animation-fill-mode: forwards;
  }

  // Hero Image Container
  .Image-Container-Animation {
    animation-name: heroImageContainerAnimation;
    animation-duration: 1.7s;
    animation-delay: 0.1s;
    transform: translateY(30vw);
    animation-fill-mode: forwards;
  }

  // Hero Image Background
  .Image-Animation {
    transform: translateX(3vw) translateY(2.1vw);
    animation-duration: 1s;
    animation-delay: 0.8s;
  }

  // Hero Image
  .Image-Img-Animation {
    animation-duration: 1s;
    animation-delay: 0.8s;
    transform: scale(1.3);
  }

  // SERVICES SECTION
  .ServiceContainer--Open {
    animation-name: serviceContainerOpen;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-delay: 0.3s;
    height: 5vw;
  }

  .ServiceContainer--Open2 {
    animation-name: serviceContainerOpen2;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-delay: 0.3s;
    height: 5vw;
  }

  .ServiceContainer--Close {
    animation-name: serviceContainerClose;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-delay: 0;
    height: 22vw;
  }

  .ServiceContainer--Close2 {
    animation-name: serviceContainerClose2;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-delay: 0;
    height: 19.5vw;
  }

  // Project List
  .ProjectsList-Categories-Animation {
    animation-delay: 1.6s;
  }

  .ProjectList-Contaier-Animation {
    animation-delay: 2s;
  }
}
