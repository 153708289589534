.LoaderContainer {
  width: 100%;
  min-height: 60vh;
  padding-top: 10vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.Loader {
  width: 20%;
  margin-top: 2vw;
}

@media screen and (min-width: 1099px) {
  .LoaderContainer {
    width: 100%;
    min-height: 30vw;
    padding-top: 4vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .Loader {
    width: 5%;
  }
}
