.ProjectDetail-Container {
  width: 90%;

  margin: 0 auto;
  padding: 25vw 0 0;
  display: flex;
  flex-direction: column;
  &__Image {
    width: 100%;
    height: 102vw;

    &--image {
      width: 100%;
      opacity: 0;
      will-change: opacity;
    }
  }
  &__Title {
    margin: 5vw 0 8vw;
    font-weight: 600;
    font-size: 10vw;
    opacity: 0;
    will-change: opacity;
  }
  &__Subcontainer {
    margin-bottom: 10vw;
    opacity: 0;
    will-change: opacity transform;
    transform: translateY(30vw);
    &--Subtitle {
      margin: 0 0 5vw;
      font-weight: 600;
      font-size: 6vw;
    }
    &--Text {
      margin: 0;
      font-size: 4.5vw;
      line-height: 155%;
      color: #545454;
    }
    &--TextMoreButton {
      font-weight: 600;
      font-size: 4.5vw;
      margin-top: 6vw;
      font-family: $Font1;
      display: flex;
      align-items: center;
      & img {
        width: 3.5vw;
        margin-left: 2vw;
        transition: 0.3s;
        will-change: transform;
      }
    }
    &--ProjectLabels {
      border-bottom: 1px solid #aaaaaa;
      display: flex;
      margin-top: 15vw;
    }
  }
}

.TextMoreButton--ArrowUp {
  transform: rotateZ(-180deg);
}
.TextMoreButton--ArrowDown {
  margin-top: 0.3vw;
  transform: rotateZ(0deg);
}

.ProjectLabels-Text {
  margin: 0 0 3vw;
  font-size: 4vw;
  color: $color-secundario;
  font-family: $Font3;

  &:nth-child(2) {
    width: 60%;
  }
  &:nth-child(3) {
    width: 40%;
  }
}

.ProjectServicesLocation {
  display: flex;
  &__Services {
    width: 60%;
    &--Text {
      margin: 3vw 0;
      font-size: 4.5vw;
      font-weight: 600;
      font-family: $Font1;
    }
  }
  &__Location {
    width: 40%;
    &--Text {
      margin: 3vw 0;
      font-size: 4.5vw;
      font-weight: 600;
      line-height: 170%;
      font-family: $Font1;
    }
  }
}

.Description2 {
  display: none;
}

.DescriptionActive {
  display: block;
}

.MoreButtonInactive {
  display: none;
}

.ProjectDetail-Container__Image.Desktop {
  display: none;
}

@media screen and (min-width: 1099px) {
  .ProjectDetail-Container {
    width: 80%;
    padding: 9vw 0 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &__Image {
      width: 100%;
      height: 41.8vw;
    }
    &__Title {
      width: 100%;
      margin: 4vw 0 1vw;
      font-size: 4vw;
    }
    &__Subcontainer {
      position: relative;
      padding-top: 11vw;
      margin-bottom: 5vw;
      transform: translateY(8vw);

      &--Subtitle {
        width: 50%;

        margin: 0 0 2vw 7.5%;
        font-size: 2vw;
      }
      &--Text {
        width: 50%;
        font-size: 1.1vw;
        margin: 0vw 0 2vw 7.5%;
      }
      &--TextMoreButton {
        font-size: 1.3vw;
        margin-left: 7.5%;
        margin-top: 0;
        cursor: pointer;
        & img {
          width: 0.9vw;
          margin-left: 0.5vw;
        }
      }
      &--ProjectLabels {
        border-bottom: 1px solid #aaaaaa;
        width: 85%;
        margin: 0;
        top: 6vw;
        left: 7.5%;
        right: 7.5%;
        display: flex;
        position: absolute;
      }
    }
  }

  .ProjectDetail-Container__Image.Mobile {
    display: none;
  }
  .ProjectDetail-Container__Image.Desktop {
    display: flex;
  }

  .ProjectLabels-Text {
    margin: 0 0 1vw;
    font-size: 1vw;
    color: $color-secundario;
    font-family: $Font3;
    &:nth-child(1) {
      width: 75%;
    }
    &:nth-child(2) {
      width: 15%;
    }
    &:nth-child(3) {
      width: 10%;
    }
  }

  .ProjectServicesLocation {
    width: 21.4%;
    display: flex;
    position: absolute;
    right: 7.5%;
    top: 11vw;
    &__Services {
      width: 60%;

      &--Text {
        margin: 0 0 1vw;
        font-size: 1.1vw;
      }
    }
    &__Location {
      width: 40%;
      &--Text {
        margin: -0.3vw 0;
        font-size: 1.1vw;
        line-height: 150%;
      }
    }
  }
}
