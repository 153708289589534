.NavBar {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}

.NavBarContainer {
  width: 90%;
  margin: 4vw auto 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1vw 0;
  &__MenuButton {
    width: 8.5vw;
    height: 8.5vw;
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
  &__Logo {
    width: 25vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    &--Image {
      width: 100%;
    }
  }
  &__LanguageButton {
    padding: 1vw 2vw;
    right: 0;
    font-size: 4vw;
    font-weight: 500;
    transition: 0.3s;
    display: flex;
    position: absolute;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    will-change: box-shadow transform;
    font-family: $Font1;
    color: $color-secundario;
    &--Selected {
      width: 100%;
      margin: 0;
      display: flex;
      align-items: center;
      letter-spacing: 1px;
    }
  }
}

.NavBarContainer__MenuButton.MenuButtonFixed {
  position: fixed;
  left: 5%;
  top: 4vw;
}

.Menu-Button-Span {
  height: 3vw;
  width: 3vw;
  position: absolute;
  transition: 0s cubic-bezier(0.69, 0.01, 0, 0.01);
  will-change: background transform;
  border-radius: 100vw;
}

.Menu-Button-1 {
  background-color: $color-primario;
  transform: translateX(-2.75vw) translateY(-2.75vw);
}
.Menu-Button-2 {
  background-color: $color-primario;
  transform: translateX(2.75vw) translateY(-2.75vw);
}
.Menu-Button-3 {
  background-color: $color-primario;
  transform: translateX(-2.75vw) translateY(2.75vw);
}
.Menu-Button-4 {
  background-color: $color-variacion2;
  transform: translateX(2.75vw) translateY(2.75vw);
}

.menuButtonIsActive {
  animation-name: menuButtonActive;
  animation-duration: 0.6s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  background-color: $color-variacion2;
}

.menuButtonIsInactive1 {
  animation-name: menuButtonInactive1;
  animation-duration: 1s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  transform: translateX(0) translateY(0);
}
.menuButtonIsInactive2 {
  animation-name: menuButtonInactive2;
  animation-duration: 1s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  transform: translateX(0) translateY(0);
}
.menuButtonIsInactive3 {
  animation-name: menuButtonInactive3;
  animation-duration: 1s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  transform: translateX(0) translateY(0);
}
.menuButtonIsInactive4 {
  animation-name: menuButtonInactive4;
  animation-duration: 1s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  transform: translateX(0) translateY(0);
}

.Menu-Button-Close {
  width: 100%;
  height: 100%;
  z-index: 600;
  border-radius: 50vw;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  will-change: transform;
  transform: scale(0);
  transition: 0.7s cubic-bezier(0.69, 0.01, 0, 0.01);
  background-color: $color-variacion2;
}

.Menu-Button-Close img {
  width: 3.5vw;
  border-radius: 100vw;
  position: absolute;
}

.menuCloseIsActive {
  transform: scale(0);
  animation-name: ScaleAnimationIn;
  animation-duration: 0.6s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

.menuCloseIsInactive {
  transform: scale(1);
  animation-name: ScaleAnimationOut;
  animation-duration: 0.6s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

.Menu {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  margin: 0;
  will-change: opacity;
  position: fixed;
  animation-fill-mode: forwards;
  background-color: $color-bgoscuro;
}

.MenuContainer {
  width: 90%;
  margin: 0 auto;
  padding: 0 0 4vw;
  overflow: hidden;
  &__Top {
    width: 97%;
    margin: 0 auto;

    padding: 5vw 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;

    &--Logo {
      width: 25vw;
      margin: 0;
      will-change: transform;
    }
    &--LanguageButton {
      width: auto;
      font-size: 4vw;
      will-change: transform;
      position: absolute;
      right: 0;
      top: 6vw;
      font-family: $Font1;
      color: $color-claro;
    }
  }
  &__Links {
    width: 50vw;
    margin: 10vw 0 5vw;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &--Options {
      margin: 4vw 0;
      text-decoration: none;
      will-change: transform;
      font-size: 8vw;
      font-weight: 500;
      color: $color-claro;
      font-family: $Font1;
    }
  }
  &__Media {
    margin: 10vw 0 0;
    padding: 7vw 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    justify-content: space-evenly;
    &--Line {
      width: 100%;
      top: 0;
      display: flex;
      position: absolute;
      will-change: transform;
      border-top: 2px solid #333;
      transform: translateX(-100vw);
    }
    &--Link {
      width: 10vw;
      height: 10vw;
      display: flex;
      align-items: center;
      justify-content: center;
      will-change: transform;
    }
  }
  &__Contact {
    width: 63vw;
    overflow: hidden;
    margin: 0 0;
    &--Arrow {
      will-change: transform;
      transform: scale(0);
    }
    &--Text {
      padding: 2vw 6vw;
      text-decoration: none;
      will-change: transform;
      display: flex;
    }
  }
}

.Menu-Ig {
  width: 60%;
}
.Menu-Be {
  width: 70%;
}
.Menu-In {
  width: 50%;
}
.Menu-Wsp {
  width: 60%;
}

.NavBar-Line {
  display: none;
}

@media screen and (min-width: 1099px) {
  .NavBar {
    padding: 2vw 0 1vw;
  }
  .NavBarContainer {
    padding: 0;
    margin: 0 auto;
    &__MenuButton {
      width: 2vw;
      height: 2vw;
      top: 0;
      margin: -0.3vw 0 0;
      cursor: pointer;
    }
    &__Logo {
      width: 7vw;
      margin: 0 auto;
      cursor: pointer;
    }
    &__LanguageButton {
      z-index: 3;
      top: 0;
      font-size: 1vw;
      padding: 0.3vw 0.5vw;
      cursor: pointer;
      will-change: text-transform;
      &:hover .NavBarContainer__LanguageButton--Selected {
        text-decoration: underline;
      }
      &--Selected {
        letter-spacing: 1px;
      }
    }
  }

  .NavBarContainer__MenuButton.MenuButtonFixed {
    left: 5%;
    top: 2vw;
  }

  .Menu-Button-Span {
    height: 0.65vw;
    width: 0.65vw;
  }

  .Menu-Button-1 {
    transform: translateX(-0.6vw) translateY(-0.6vw);
  }
  .Menu-Button-2 {
    transform: translateX(0.6vw) translateY(-0.6vw);
  }
  .Menu-Button-3 {
    transform: translateX(-0.6vw) translateY(0.6vw);
  }
  .Menu-Button-4 {
    transform: translateX(0.6vw) translateY(0.6vw);
  }

  .Menu-Button-Close {
    width: 120%;
    height: 120%;
  }

  .Menu-Button-Close:hover {
    background-color: $color-terciario;
  }

  .Menu-Button-Close img {
    width: 0.9vw;
  }

  .menuButtonIsInactive1 {
    transform: translateX(0) translateY(0);
  }
  .menuButtonIsInactive2 {
    transform: translateX(0) translateY(0);
  }
  .menuButtonIsInactive3 {
    transform: translateX(0) translateY(0);
  }
  .menuButtonIsInactive4 {
    transform: translateX(0) translateY(0);
  }

  .MenuContainer {
    height: 90vh;
    padding: 2vw 0 4vw;
    &__Top {
      padding: 0 0;

      &--Logo {
        width: 7vw;
        margin: 0 auto;
      }
      &--LanguageButton {
        display: none;
      }
    }
    &__Links {
      width: 100%;
      height: 4.5vw;
      margin-top: 14vw;
      flex-direction: row;
      justify-content: space-between;
      &--Options {
        margin: 0;
        font-size: 3.3vw;
        transition: 0.5s;
        cursor: pointer;
        &:hover {
          color: $color-variacion2;
        }
      }
    }
    &__Media {
      width: 16vw;
      height: 4vw;
      bottom: 4vw;
      left: 15%;
      margin: 0;
      padding: 0;
      position: absolute;
      overflow: hidden;
      &--Line {
        display: none;
      }
    }
    &__Contact {
      width: 17.5vw;
      right: 20%;
      bottom: 3vw;
      margin: 0;
      position: absolute;
      &--Text {
        padding: 0vw;
        display: flex;
      }
    }
  }

  .Menu-Media-Animation.Media-Animation {
    background-color: $color-variacion2;
  }

  .NavBar-Line-Container {
    width: 90%;
    height: 0.5vw;
    bottom: 13vw;
    display: flex;
    position: absolute;
    overflow: hidden;
  }

  .NavBar-Line {
    width: 100%;
    display: flex;
    border-top: 2px solid $color-terciario;
  }
}
