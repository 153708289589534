$color-claro: #fff;
$color-primario: #000000;
$color-secundario: #474747;
$color-terciario: #8c8888;
$color-variacion1: #8c32ff;
$color-variacion2: #7647ea;
$color-bgoscuro: #131313;
$Font1: "ClashDisplay-Variable";
$Font2: "Lexend Deca", sans-serif;
$Font3: "Poppins", sans-serif;
