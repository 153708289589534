.MoreProjectsContainer {
  padding: 15vw 0;
  background-color: $color-bgoscuro;
  &__Text {
    margin: 0 0 5vw 15%;
    font-weight: 600;

    color: $color-claro;
    &--en {
      width: 45%;
    }
    &--es {
      width: 40%;
    }
  }
  &__MoreButton {
    display: flex;
    width: 82%;
    margin: 0 0 0 15%;
    text-decoration: none;
  }
}

.disabled {
  pointer-events: none;
}

@media screen and (min-width: 1099px) {
  .MoreProjectsContainer {
    padding: 6vw 0;
    display: flex;
    justify-content: space-evenly;
    &__Text {
      margin: 0 0 0 4%;
      &--en {
        width: 24%;
      }
      &--es {
        width: 20%;
      }
    }
    &__MoreButton {
      width: 24%;
      margin: 0;
    }
  }
}
