.SectionTitle.Intersecting,
.Title.Intersecting,
.Text.Intersecting,
.Button.Intersecting,
.ProjectBox.Intersecting,
.HomeServicesContainer-Subcontainer.Intersecting,
.HomeServicesContainer-ImageContainer.Intersecting,
.ProjectTemplateOne__Image.Intersecting,
.ProjectTemplateTwo__Image.Intersecting,
.ProjectTemplateThree__Image.Intersecting,
.HomeProjectsContainer__MoreButton.Intersecting {
  transform: translateY(0);
  opacity: 1;
}

.SectionTitle.NotIntersecting,
.Title.NotIntersecting,
.Text.NotIntersecting,
.Button.NotIntersecting,
.ProjectBox.NotIntersecting,
.HomeServicesContainer-Subcontainer.NotIntersecting,
.HomeServicesContainer-ImageContainer.NotIntersecting,
.ProjectTemplateOne__Image.NotIntersecting,
.ProjectTemplateTwo__Image.NotIntersecting,
.ProjectTemplateThree__Image.NotIntersecting,
.HomeProjectsContainer__MoreButton.NotIntersecting {
  opacity: 0.1;
  transform: translateY(15vw);
}

// .HomeProjects.Intersecting {
//   background-color: $color-bgoscuro;
// }

// .HomeProjects.NotIntersecting {
//   background-color: $color-claro;
// }

.HomeProjectsContainer__Line.Intersecting {
  width: 50vw;
  opacity: 1;
}
.HomeProjectsContainer__Line.NotIntersecting {
  width: 0vw;
  opacity: 0.3;
}

// .HomeMetodology.Intersecting {
//   opacity: 1;
// }
// .HomeMetodology.NotIntersecting {
//   opacity: 0;
// }

.HomeMetodologyContainer__SectionTitle.BgIntersecting {
  opacity: 1;
}
.HomeMetodologyContainer__SectionTitle.BgNotIntersecting {
  opacity: 0;
}

.HomeMetodologyContainer__Subcontainer.Intersecting {
  opacity: 1;
}
.HomeMetodologyContainer__Subcontainer.NotIntersecting {
  opacity: 0.2;
}

.HomeMetodologyContainer__Subcontainer--Line.Intersecting {
  width: 85%;
}
.HomeMetodologyContainer__Subcontainer--Line.NotIntersecting {
  width: 0%;
}

.HomeMetodologyContainer__Subcontainer--Number.Intersecting {
  color: $color-variacion1;
}
.HomeMetodologyContainer__Subcontainer--Number.NotIntersecting {
  color: $color-secundario;
}

@media screen and (min-width: 1099px) {
  .SectionTitle.Intersecting,
  .Title.Intersecting,
  .Text.Intersecting,
  .Button.Intersecting,
  .ProjectBox.Intersecting,
  .HomeServicesContainer-Subcontainer.Intersecting,
  .HomeServicesContainer-ImageContainer.Intersecting,
  .HomeProjectsContainer__MoreButton.Intersecting {
    transform: translateY(0);
    opacity: 1;
  }

  .SectionTitle.NotIntersecting,
  .Title.NotIntersecting,
  .Text.NotIntersecting,
  .Button.NotIntersecting,
  .ProjectBox.NotIntersecting,
  .HomeServicesContainer-Subcontainer.NotIntersecting,
  .HomeServicesContainer-ImageContainer.NotIntersecting,
  .HomeProjectsContainer__MoreButton.NotIntersecting {
    opacity: 0.1;
    transform: translateY(4vw);
  }

  .ProjectTemplateOne__Image.NotIntersecting {
    opacity: 0.1;
    &:nth-child(2) {
      transform: translateY(33vw);
    }
    &:nth-child(3) {
      transform: translateY(15vw);
    }
    &:nth-child(4) {
      transform: translateY(25vw);
    }
    &:nth-child(5) {
      transform: translateY(36vw);
    }
    &:nth-child(6) {
      transform: translateY(44vw);
    }
    &:nth-child(7) {
      transform: translateY(73vw);
    }
    &:nth-child(8) {
      transform: translateY(56vw);
    }
  }
  .ProjectTemplateOne__Image.Intersecting {
    opacity: 1;
    &:nth-child(2) {
      transform: translateY(29vw);
    }
    &:nth-child(3) {
      transform: translateY(11vw);
    }
    &:nth-child(4) {
      transform: translateY(21vw);
    }
    &:nth-child(5) {
      transform: translateY(32vw);
    }
    &:nth-child(6) {
      transform: translateY(40vw);
    }
    &:nth-child(7) {
      transform: translateY(69vw);
    }
    &:nth-child(8) {
      transform: translateY(52vw);
    }
  }

  .ProjectTemplateTwo__Image.NotIntersecting {
    opacity: 0.1;
    &:nth-child(2) {
      transform: translateY(20vw);
    }
    &:nth-child(3) {
      transform: translateY(1vw);
    }
    &:nth-child(4) {
      transform: translateY(14vw);
    }
    &:nth-child(5) {
      transform: translateY(40vw);
    }
    &:nth-child(6) {
      transform: translateY(24vw);
    }
    &:nth-child(7) {
      transform: translateY(49vw);
    }
    &:nth-child(8) {
      transform: translateY(57vw);
    }
    &:nth-child(9) {
      transform: translateY(69vw);
    }
    &:nth-child(10) {
      transform: translateY(78vw);
    }
    &:nth-child(11) {
      transform: translateY(87vw) translateX(57%);
    }
    &:nth-child(12) {
      transform: translateY(94vw);
    }
  }
  .ProjectTemplateTwo__Image.Intersecting {
    opacity: 1;
    &:nth-child(2) {
      transform: translateY(16vw);
    }
    &:nth-child(3) {
      transform: translateY(-3vw);
    }
    &:nth-child(4) {
      transform: translateY(10vw);
    }
    &:nth-child(5) {
      transform: translateY(36vw);
    }
    &:nth-child(6) {
      transform: translateY(20vw);
    }
    &:nth-child(7) {
      transform: translateY(45vw);
    }
    &:nth-child(8) {
      transform: translateY(53vw);
    }
    &:nth-child(9) {
      transform: translateY(65vw);
    }
    &:nth-child(10) {
      transform: translateY(74vw);
    }
    &:nth-child(11) {
      transform: translateY(83vw) translateX(57%);
    }
    &:nth-child(12) {
      transform: translateY(90vw);
    }
  }

  .ProjectTemplateThree__Image.NotIntersecting {
    opacity: 0.1;

    &:nth-child(2) {
      transform: translateY(20vw);
    }
    &:nth-child(3) {
      transform: translateY(5vw);
    }
    &:nth-child(4) {
      transform: translateY(51vw);
    }
    &:nth-child(5) {
      transform: translateY(-9vw);
    }
    &:nth-child(6) {
      transform: translateY(-8vw) translateX(108.5%);
    }
    &:nth-child(7) {
      transform: translateY(20vw);
    }
    &:nth-child(8) {
      transform: translateY(47vw);
    }
    &:nth-child(9) {
      transform: translateY(31vw);
    }
    &:nth-child(10) {
      transform: translateY(56vw);
    }
    &:nth-child(11) {
      transform: translateY(72vw);
    }
    &:nth-child(12) {
      transform: translateY(88vw);
    }
    &:nth-child(13) {
      transform: translateY(106vw);
    }
    &:nth-child(14) {
      transform: translateY(122vw) translateX(54%);
    }
    &:nth-child(15) {
      transform: translateY(129vw);
    }
  }
  .ProjectTemplateThree__Image.Intersecting {
    opacity: 1;

    &:nth-child(2) {
      transform: translateY(16vw);
    }
    &:nth-child(3) {
      transform: translateY(1vw);
    }
    &:nth-child(4) {
      transform: translateY(47vw);
    }
    &:nth-child(5) {
      transform: translateY(-13vw);
    }
    &:nth-child(6) {
      transform: translateY(-12vw) translateX(108.5%);
    }
    &:nth-child(7) {
      transform: translateY(16vw);
    }
    &:nth-child(8) {
      transform: translateY(43vw);
    }
    &:nth-child(9) {
      transform: translateY(27vw);
    }
    &:nth-child(10) {
      transform: translateY(52vw);
    }
    &:nth-child(11) {
      transform: translateY(68vw);
    }
    &:nth-child(12) {
      transform: translateY(84vw);
    }
    &:nth-child(13) {
      transform: translateY(102vw);
    }
    &:nth-child(14) {
      transform: translateY(118vw) translateX(54%);
    }
    &:nth-child(15) {
      transform: translateY(125vw);
    }
  }

  .HomeProjectsContainer__Description.Intersecting {
    opacity: 1;
    transform: translateX(0);
  }

  .HomeProjectsContainer__Description.NotIntersecting {
    opacity: 0.3;
    transform: translateX(5vw);
  }

  .HomeProjectsContainer__Line.Intersecting {
    width: 40vw;
    opacity: 1;
  }
  .HomeProjectsContainer__Line.NotIntersecting {
    width: 0vw;
    opacity: 0.3;
  }

  .HomeMetodologyContainer__Subcontainer--Number.Intersecting {
    color: $color-secundario;
  }
  .HomeMetodologyContainer__Subcontainer--Number.NotIntersecting {
    color: $color-secundario;
  }

  .HomeMetodologyContainer__Subcontainer--Line.Intersecting {
    width: 100%;
  }
  .HomeMetodologyContainer__Subcontainer--Line.NotIntersecting {
    width: 5%;
  }
}
