// Skeleton animation
@keyframes SkeletonAnimation {
  0% {
    transform: rotateZ(0deg) scale(2);
  }
  100% {
    transform: rotateZ(360deg) scale(2);
  }
}

// --Scale animation
@keyframes ScaleAnimationOut {
  0% {
    transform: scale(1);
  }
  50% {
  }
  100% {
    transform: scale(0);
  }
}
@keyframes ScaleAnimationIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

// --Opacity animation
@keyframes opacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// RotateZ animation
@keyframes rotateZanimationIn {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(180deg);
  }
}

@keyframes rotateZanimationOut {
  0% {
    transform: rotateZ(180deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

//Span translate
@keyframes textTranslateAnimation {
  0% {
    transform: translateY(10vw);
  }
  100% {
    transform: translateY(0);
  }
}

// Header
@keyframes headerAnimation {
  0% {
    transform: translateY(-20vw);
  }
  100% {
    transform: translateY(0);
  }
}

// --menu
@keyframes menuClose {
  0% {
    opacity: 1;
    z-index: 100;
    pointer-events: none;
  }

  40% {
    opacity: 1;
    z-index: 100;
    pointer-events: none;
  }

  99% {
    z-index: 100;
    pointer-events: none;
  }

  100% {
    opacity: 0;
    z-index: -10;
    pointer-events: none;
  }
}
@keyframes menuOpen {
  0% {
    opacity: 0;
    z-index: -10;
    pointer-events: none;
  }
  99% {
    pointer-events: none;
  }
  100% {
    opacity: 1;
    z-index: 100;
    pointer-events: all;
  }
}

@keyframes menuButtonActive {
  0% {
  }
  100% {
    transform: translateX(0) translateY(0);
  }
}

@keyframes menuButtonInactive1 {
  0% {
    transform: translateX(0) translateY(0);
    background-color: $color-variacion2;
  }
  70% {
    transform: translateX(-2.75vw) translateY(-2.75vw);
    background-color: $color-variacion2;
  }
  100% {
    background-color: $color-primario;
    transform: translateX(-2.75vw) translateY(-2.75vw);
  }
}

@keyframes menuButtonInactive2 {
  0% {
    transform: translateX(0) translateY(0);
  }
  70% {
    transform: translateX(2.75vw) translateY(-2.75vw);
    background-color: $color-variacion2;
  }
  100% {
    background-color: $color-primario;
    transform: translateX(2.75vw) translateY(-2.75vw);
  }
}

@keyframes menuButtonInactive3 {
  0% {
    transform: translateX(0) translateY(0);
  }
  70% {
    transform: translateX(-2.75vw) translateY(2.75vw);
    background-color: $color-variacion2;
  }
  100% {
    background-color: $color-primario;
    transform: translateX(-2.75vw) translateY(2.75vw);
  }
}

@keyframes menuButtonInactive4 {
  0% {
    transform: translateX(0) translateY(0);
  }
  70% {
    transform: translateX(2.75vw) translateY(2.75vw);
  }
  100% {
    transform: translateX(2.75vw) translateY(2.75vw);
  }
}

// --Line TranslateX
@keyframes navbarLineTranslateXOut {
  0% {
    transform: translateX(0vw);
  }
  100% {
    transform: translateX(100vw);
  }
}
@keyframes navbarLineTranslateXIn {
  0% {
    transform: translateX(-100vw);
  }
  100% {
    transform: translateX(0vw);
  }
}

// --TranslateY
@keyframes navbarTranslateYAnimationOut {
  0% {
    transform: translateY(0w);
  }
  100% {
    transform: translateY(-30vw);
  }
}
@keyframes navbarTranslateYAnimationIn {
  0% {
    transform: translateY(30w);
  }

  100% {
    transform: translateY(0vw);
  }
}

// --TranslateX
@keyframes navbarTranslateXAnimationIn {
  0% {
    transform: translateX(-45vw);
  }
  50% {
    transform: translateX(3vw);
  }
  100% {
    transform: translateX(0vw);
  }
}
@keyframes navbarTranslateXAnimationOut {
  0% {
    transform: translateX(0vw);
  }
  50% {
    transform: translateX(5vw);
  }
  100% {
    transform: translateX(-45vw);
  }
}

// HERO
// Hero Image Container
@keyframes heroImageImgAnimation {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

// Hero Image Background
@keyframes heroImageContainerAnimation {
  0% {
    background-size: 60vw;
  }
  100% {
    background-size: 81vw;
  }
}

// Hero Image
@keyframes heroImageAnimation {
  0% {
    transform: translateX(7.5vw) translateY(10vw);
  }
  100% {
    transform: translateX(0) translateY(0vw);
  }
}

// SERVICES
@keyframes serviceContainerOpen {
  0% {
    height: 20vw;
  }

  100% {
    height: 100vw;
  }
}

@keyframes serviceContainerClose {
  0% {
    height: 100vw;
  }

  100% {
    height: 20vw;
  }
}

@keyframes serviceContainerOpen2 {
  0% {
    height: 20vw;
  }

  100% {
    height: 90vw;
  }
}

@keyframes serviceContainerClose2 {
  0% {
    height: 90vw;
  }

  100% {
    height: 20vw;
  }
}

@keyframes ProjectDetailDescription {
  0% {
    transform: translateY(30vw);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@media screen and (min-width: 1099px) {
  // Skeleton Animation
  @keyframes SkeletonAnimation {
    0% {
      transform: rotateZ(0deg) scale(3);
    }
    100% {
      transform: rotateZ(360deg) scale(3);
    }
  }
  // --Scale animation
  @keyframes ScaleAnimationOut {
    0% {
      transform: scale(1);
    }
    50% {
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes ScaleAnimationIn {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  // --Opacity animation
  @keyframes opacityAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes menuButtonActive {
    0% {
    }
    100% {
      transform: translateX(0) translateY(0);
    }
  }

  @keyframes menuButtonInactive1 {
    0% {
      background-color: $color-variacion2;
      transform: translateX(0) translateY(0);
    }
    70% {
      background-color: $color-variacion2;
      transform: translateX(-0.6vw) translateY(-0.6vw);
    }
    100% {
      background-color: $color-primario;
      transform: translateX(-0.6vw) translateY(-0.6vw);
    }
  }

  @keyframes menuButtonInactive2 {
    0% {
      background-color: $color-variacion2;
      transform: translateX(0) translateY(0);
    }
    70% {
      background-color: $color-variacion2;
      transform: translateX(0.6vw) translateY(-0.6vw);
    }
    100% {
      background-color: $color-primario;
      transform: translateX(0.6vw) translateY(-0.6vw);
    }
  }

  @keyframes menuButtonInactive3 {
    0% {
      background-color: $color-variacion2;
      transform: translateX(0) translateY(0);
    }
    70% {
      background-color: $color-variacion2;
      transform: translateX(-0.6vw) translateY(0.6vw);
    }

    100% {
      background-color: $color-primario;
      transform: translateX(-0.6vw) translateY(0.6vw);
    }
  }

  @keyframes menuButtonInactive4 {
    0% {
      transform: translateX(0) translateY(0);
    }
    70% {
      transform: translateX(0.6vw) translateY(0.6vw);
    }
    100% {
      transform: translateX(0.6vw) translateY(0.6vw);
    }
  }

  // --TranslateY
  @keyframes navbarTranslateYAnimationOut {
    0% {
      transform: translateY(0w);
    }

    100% {
      transform: translateY(-6vw);
    }
  }
  @keyframes navbarTranslateYAnimationIn {
    0% {
      transform: translateY(6vw);
    }

    100% {
      transform: translateY(0vw);
    }
  }

  // --TranslateX
  @keyframes navbarTranslateXAnimationIn {
    0% {
      transform: translateX(-100vw);
    }
    50% {
      transform: translateX(3vw);
    }
    100% {
      transform: translateX(0vw);
    }
  }
  @keyframes navbarTranslateXAnimationOut {
    0% {
      transform: translateX(0vw);
    }
    50% {
      transform: translateX(5vw);
    }
    100% {
      transform: translateX(-100vw);
    }
  }

  // HERO
  // Hero Image Container
  @keyframes heroImageContainerAnimation {
    0% {
      background-size: 0vw;
      transform: translateY(10vw);
    }

    17% {
      background-size: 0vw;
      transform: translateY(0vw);
    }

    41% {
      background-size: 10vw;
      transform: translateY(0vw);
    }

    100% {
      background-size: 34vw;
      opacity: 1;
      transform: translateY(0vw);
    }
  }
  // Hero Image Background

  @keyframes heroImageAnimation {
    0% {
      transform: translateX(3vw) translateY(2.1vw);
    }
    100% {
      transform: translateX(0) translateY(0);
    }
  }
  // Hero Image

  @keyframes heroImageImgAnimation {
    0% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }

  // SERVICES
  // Services slide animation
  @keyframes slideAnimation1 {
    0% {
      opacity: 0;
    }
    10% {
      margin-left: 0;
    }
    90% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      margin-left: 0;
    }
  }
  @keyframes slideAnimation2 {
    0% {
      opacity: 0;
    }
    10% {
      margin-left: -100%;
    }
    90% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      margin-left: -100%;
    }
  }
  @keyframes slideAnimation3 {
    0% {
      opacity: 0;
    }
    10% {
      margin-left: -200%;
    }
    90% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      margin-left: -200%;
    }
  }
  @keyframes slideAnimation4 {
    0% {
      opacity: 0;
    }
    10% {
      margin-left: -300%;
    }
    90% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      margin-left: -300%;
    }
  }

  // SERVICES
  @keyframes serviceContainerOpen {
    0% {
      height: 5vw;
    }

    100% {
      height: 22vw;
    }
  }

  @keyframes serviceContainerClose {
    0% {
      height: 22vw;
    }

    100% {
      height: 5vw;
    }
  }

  @keyframes serviceContainerOpen2 {
    0% {
      height: 5vw;
    }

    100% {
      height: 19.5vw;
    }
  }

  @keyframes serviceContainerClose2 {
    0% {
      height: 19.5vw;
    }

    100% {
      height: 5vw;
    }
  }

  @keyframes ProjectDetailDescription {
    0% {
      transform: translateY(8vw);
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
