.border-red {
  border: 2px solid red;
}

.display-none {
  display: none;
}

.SectionTitle {
  display: flex;
  align-items: center;
  margin: 0 0 5vw;
  font-size: 4vw;
  font-weight: 400;
  transition: 1s;
  will-change: opacity;
  font-family: $Font2;
  &__Circle {
    width: 2.3vw;
    height: 2.3vw;
    border-radius: 50vw;
    margin-right: 1.5vw;
    background-color: $color-variacion2;
  }
}

.Title {
  font-weight: 500;
  line-height: 115%;
  will-change: opacity;
  transition: 1s;
  font-family: $Font1;
}

.Title-BigFont {
  font-size: 9vw;
}

.Title-MediumFont {
  font-size: 6.8vw;
}

.Title-SmallFont {
  font-size: 5vw;
}

.Text {
  font-weight: 300;
  line-height: 140%;
  will-change: opacity;
  transition: 1s;
  font-family: $Font2;
}

.Text-BigFont {
  font-size: 4vw;
}

.Text-MediumFont {
  font-size: 3.8vw;
}

.Text-SmallFont {
  font-size: 3.3vw;
}

.Desktop {
  display: none;
}

.Mobile {
  display: block;
}

.Media-Container-Animation {
  width: 8vw;
  height: 8vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.Media-Ig {
  width: 60%;
}
.Media-Be {
  width: 70%;
}
.Media-In {
  width: 50%;
}
.Media-Wsp {
  width: 60%;
}

@media screen and (min-width: 1099px) {
  .SectionTitle {
    margin: 0 0 4vw;
    font-size: 18px;
    &__Circle {
      width: 0.6vw;
      height: 0.6vw;
      margin-right: 0.5vw;
    }
  }

  .Title-BigFont {
    font-size: 4vw;
  }

  .Title-MediumFont {
    font-size: 3.3vw;
  }

  .Title-SmallFont {
    font-size: 1.3vw;
  }

  .Text {
    line-height: 150%;
  }

  .Text-BigFont {
    font-size: 1.17vw;
  }

  .Text-MediumFont {
    font-size: 1.1vw;
  }

  .Text-SmallFont {
    font-size: 0.9vw;
  }

  .Desktop {
    display: flex;
  }

  .Mobile {
    display: none;
  }

  // Social media animation
  .Media-Container-Animation {
    width: 2.3vw;
    height: 2.3vw;
    border-radius: 50vw;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .Media-Animation {
    display: flex;
    width: 2.3vw;
    height: 2.3vw;
    opacity: 0;
    transition: 0.3s;
    will-change: transform;
    border-radius: 50vw;
    position: absolute;
    transform: scale(0.1);
    background-color: transparent;
  }

  .Social-Media-Image {
    z-index: 2;
    will-change: filter;
    transition: 0.3s;
  }

  .Media-Container-Animation:hover .Media-Animation {
    transform: scale(1);
    opacity: 1;
  }

  .Media-Ig {
    width: 55%;
    margin-left: -0.05vw;
  }
  .Media-Be {
    width: 65%;
  }
  .Media-In {
    width: 50%;
  }
}
