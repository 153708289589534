.ProjectTemplateOne {
  width: 100%;
  padding-bottom: 15vw;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &__Image {
    width: 100%;
    margin-bottom: 4vw;
    will-change: opacity;
    transition: 1s;
  }
}

.ProjectTemplateTwo {
  width: 100%;
  padding-bottom: 15vw;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &__Image {
    width: 100%;
    margin-bottom: 4vw;
    will-change: opacity;
    transition: 1s;
  }
}

.ProjectTemplateThree {
  width: 100%;
  padding-bottom: 15vw;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &__Image {
    width: 90vw;
    margin-bottom: 4vw;
    will-change: opacity;
    transition: 1s;
  }
}

@media screen and (min-width: 1099px) {
  // Project Template One
  .ProjectTemplateOne {
    justify-content: space-between;
    padding: 0vw 0 60vw;
    &__Image {
      width: 48%;
      margin-bottom: 0;

      position: relative;
      &:nth-child(1) {
        width: 48%;
      }
      &:nth-child(2) {
        width: 48%;
      }
      &:nth-child(3) {
        width: 48%;
      }
      &:nth-child(4) {
        width: 100%;
      }
      &:nth-child(5) {
        width: 100%;
      }
      &:nth-child(6) {
        width: 48%;
      }
      &:nth-child(7) {
        width: 48%;
      }
      &:nth-child(8) {
        width: 48%;
      }
    }
  }

  .ProjectTemplateTwo {
    justify-content: space-between;
    padding: 0vw 0 105vw;
    &__Image {
      width: 48%;
      margin-bottom: 0;
      &:nth-child(1) {
        width: 32%;
      }
      &:nth-child(2) {
        width: 63%;
        transform: translateY(16vw);
      }
      &:nth-child(3) {
        width: 32%;
        transform: translateY(-3vw);
      }
      &:nth-child(4) {
        width: 100%;
      }
      &:nth-child(5) {
        width: 48%;
      }
      &:nth-child(6) {
        width: 48%;
      }
      &:nth-child(7) {
        width: 100%;
      }
      &:nth-child(8) {
        width: 48%;
      }
      &:nth-child(9) {
        width: 48%;
      }
      &:nth-child(10) {
        width: 100%;
      }
      &:nth-child(11) {
        width: 63.7%;
      }
      &:nth-child(12) {
        width: 65%;
      }
    }
  }

  .ProjectTemplateThree {
    justify-content: space-between;
    padding: 7vw 0 137vw;
    &__Image {
      width: 48%;
      margin-bottom: 0;
      &:nth-child(1) {
        width: 100%;
      }
      &:nth-child(2) {
        width: 66%;
      }
      &:nth-child(3) {
        width: 30%;
      }
      &:nth-child(4) {
        width: 48%;
      }
      &:nth-child(5) {
        width: 30%;
      }
      &:nth-child(6) {
        width: 48%;
      }
      &:nth-child(7) {
        width: 100%;
      }
      &:nth-child(8) {
        width: 48%;
      }
      &:nth-child(9) {
        width: 48%;
      }
      &:nth-child(10) {
        width: 100%;
      }
      &:nth-child(11) {
        width: 48.5%;
      }
      &:nth-child(12) {
        width: 48.5%;
      }
      &:nth-child(13) {
        width: 100%;
      }
      &:nth-child(14) {
        width: 65%;
      }
      &:nth-child(15) {
        width: 65%;
      }
    }
  }
}
