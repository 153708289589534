.ButtonContainer {
  display: flex;
}
.Button {
  padding: 2vw 0 2vw 16vw;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 1s;
  will-change: opacity;
}

.Button-Arrow {
  width: 20vw;
  height: 20vw;
  left: 0;
  transition: 0.3s;
  position: absolute;
  border-radius: 100vw;
  background-color: rgba(100, 100, 100, 0.5);
  background-image: url(../../Assets/Images/button-arrow.svg);
  background-size: 7vw;
  background-repeat: no-repeat;
  background-position: 6vw center;
  will-change: width background-position;
}

.Button-Text {
  white-space: nowrap;
  z-index: 2;
  font-weight: 600;
  font-size: 5vw;
  color: $color-claro;
  font-family: $Font1;
}

@media screen and (min-width: 1099px) {
  .Button {
    padding: 0.3vw 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
  }

  .Button:hover .Button-Arrow {
    width: 99%;
    background-image: url(../../Assets/Images/button-arrow-white.svg);
    background-position: 1.9vw center;
    background-color: $color-variacion2;
  }

  .Button:hover .Button-Text {
    color: $color-claro;
  }

  .Button-Arrow {
    left: 0;
    transition: 0.3s;
    width: 5vw;
    height: 5vw;
    background-size: 1.5vw;
    background-position: 1.5vw center;
    transition: 0.3s;
    will-change: width background-position background-color background-image;
  }

  .Button-Text {
    margin-left: 1vw;
    z-index: 2;
    font-size: 1.5vw;
    font-weight: 600;
    transition: 0.3s;
    will-change: color;
    color: $color-claro;
    font-family: $Font1;
  }
}
